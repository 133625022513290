html,
body {
  margin: 0;
}

#App {
  font-family: sans-serif;
  height: 100vh;
}

#page-wrap {
  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
  text-decoration: none;
}

/* Individual item */
.bm-item {
  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: white;
  margin-top: 30px;
}

/* Change color on hover */
.bm-item:hover {
  color: orange;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 25px;
  right: 18px;
  top: 21px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #111111;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: white;
}

/* General sidebar styles */
.bm-menu {
  z-index: 99999;
  background: #ff5a00;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */

/* Styling of overlay */

.menu-item {
  margin: 20px 0px;
}
