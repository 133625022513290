$swobbee_color: #f2711c;

html,
body,
#app,
#app > div {
  height: 100%;
  width: 100%;
  font-family: "Exo", sans-serif;
  margin: 0;
  padding: 0;
  background: #121212;
  -webkit-overflow-scrolling: touch;
}

.numberInput .ui.button {
  background-color: $swobbee_color;
  color: white;
}

.numberInputRed .ui.button {
  background-color: $swobbee_color;
  color: white;
}

.numberInput .ui.input > input {
  border-color: $swobbee_color;
  color: black;
  font-weight: bold;
  width: 210px;
  text-align: center;
}


.numberInputRed .ui.input > input {
  border-color: $swobbee_color;
  color: red;
  font-weight: bold;
  width: 210px;
  text-align: center;
}



.numberInput .ui.input {
  text-align: center;
}

.numberInput {
  text-align: center;
}

.ui.button {
  background-color: $swobbee_color;
}

@media only screen and (min-width: 768px) {
  .ui.button.button-over-map {
    display: none;
  }
}

.ui.button.button-over-map {
  z-index: 999;
  border-radius: 50px;
  bottom: 50px;
  position: fixed;
  color: white;
  margin-left: 42%;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#vrsn {
  z-index: 999;
  bottom: 0px;
  margin: 0px;
  position: fixed;
  font-size: large;
  left: 0px
}

.burger-menu-font {
  font-size: 20px;
  margin-left: 10px;
  text-align: center;
  color: white
}



.circle-leaflet {
  color: white;
  width: 32px;
  height: 32px;
  line-height: 32px;
  background-color: #ff5a00;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)
}

#stage {
  z-index: 99999999;
  bottom: 2px;
  position: fixed;
  font-size: large;
  right: 2px
}
