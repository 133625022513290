.swaporder-label{
    color: #e76928;
    font-size: x-large;
    text-transform: uppercase;
}

.swaporder-value{
    float: right;
    font-size: x-large;
    text-transform: uppercase;
    color: white  
}

.swaporder-status-container{
    width: 90%;
    margin-left: 100px;
}
