@media only screen and (min-width: 768px) {
  .button-over-map {
    z-index: 999;
    border-radius: 50px;
    bottom: 20px;
    position: absolute;
    margin-right: 100%;
    margin-left: 30%;
    color: white;
    display: none;
  }
}

