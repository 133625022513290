.wrapper-3-boxes { 
    display: grid; 
    grid-template-columns: repeat(3, 1fr); 
    grid-auto-rows:auto;
    width: 100%; 
  }
  
  .wrapper-2-boxes { 
    display: grid; 
    grid-template-columns: repeat(2, 1fr); 
    grid-auto-rows:auto;
    width: 20%; 
  }
  
  .box0 { 
    margin: auto;
    grid-column-start: 1; 
    grid-column-end: 1; 
    grid-row-start: 1; 
    grid-row-end: 1; 
  }
  
  .box2 { 
    margin: auto;

    grid-column-start: 2; 
    grid-column-end: 3; 
    grid-row-start: 1; 
    grid-row-end: 1; 
  }

  .box4 {
    margin: auto;

    grid-column-start: 3; 
    grid-column-end: 4; 
    grid-row-start: 1; 
    grid-row-end: 1; 
  }




  .box1 {
    margin: auto;

    grid-column-start: 1;
    grid-column-end: 2; 
    grid-row-start: 2; 
    grid-row-end: 3; 
  }



  .box3 {
    margin: auto;

    grid-column-start: 2; 
    grid-column-end: 3; 
    grid-row-start: 2; 
    grid-row-end: 3; 
  }



  .box5 {
    margin: auto;

    grid-column-start: 3; 
    grid-column-end: 4; 
    grid-row-start: 2; 
    grid-row-end: 3; 
  }

