
  @media only screen and (min-width: 300px) {
    .bt-sl-mdl{
      margin: auto;
      margin-top: 30px;
    }
  }


  @media only screen and (min-width: 768px) {
    .container-sps{
      width: 50% !important;
    }
  }
  
  .ui.dimmer{
    //background: transparent;
  }