.screen--sharing-points {
  background: #eee; //yellow;
  height: 100vh;
  overflow-y: scroll;
  padding: 1em;
  .ui.container {
    .component--sharingpoints {
      .sharingpoints.ui.list {
        .item.sp-item {
          background: rgb(200, 180, 243);
          margin: 1rem;
          padding: 10px;
          border-radius: 10px;
        }
      }
    }
  }
  .sharing-points__table {
    .header {
      margin: 10px;
    }
  }
  .ui.list {
    height: 100%;
    overflow: hidden;
    .item {
      margin-bottom: 1em;
      padding: 1em;
      background: #ddd;
      &:hover {
        background: #ccc;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .ui.container.screen--sharingpoints {
    width: 100% !important;
  }
}
@media only screen and (max-width: 767px) {
  .ui.container {
    width: auto !important;
    margin-left: 0em !important;
    margin-right: 0em !important;
  }
}
.btn {
  &--back.ui.icon.button {
    color: orange;
    background: none;
    font-size: 2rem;
  }
}
