.request-modal{
  width: 100% !important;
  margin: auto;
}

@media only screen and (min-width: 768px) {
  .request-modal{
    width: 25% !important;
    margin-left: 200px;
    height: 25%
  }
  .button-over-modal{
    display: none;
  }
}

.input-checkout-count .ui.input > input{
  width: 100%;
}